<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2">Divisi</div>
    <BCol class="p-0 d-flex">
      <div class="d-flex rounded-lg align-items-center border search-bar">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari Nama"
          @input="handleSearch()"
          class="border-0"
        />
      </div>
      <BButton
        v-b-modal.modalDetail
        variant="primary"
        class="ml-auto px-3"
        @click="initialName = ''"
      >
        <strong>Tambah</strong>
      </BButton>
    </BCol>

    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 275px); overflow-y: scroll"
        class="mt-[24px]"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                @click="selectItem(data.item.id, true)"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  alt="Komerce"
                  class="mr-[5px]"
                />
              </BButton>
              <BFormCheckbox
                v-model="data.item.status"
                switch
                @change="onUpdate(data.item, null)"
              />
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>

    <ModalDelete :id-item="idItem" @deleted="getListData()" />
    <ModalDetail :id-item="idItem" @updated="getListData()" :initialName="initialName" />
  </BCard>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { tableBisnis } from './config'
import ModalDelete from './ModalDelete.vue'
import ModalDetail from './ModalDetail.vue'

export default {
  components: {
    ModalDelete,
    ModalDetail,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableBisnis,
      idItem: 0,
      initialName: '',
      detailDivision: {},
      alertError,
      alertSuccess,
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}`
      const url = `v1/divisions?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}`
        const url = `v1/divisions?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async getDetailDivision(id) {
      this.offset = 0
      const url = `v1/divisions/${id}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data

          this.initialName = await data.division_name
          setTimeout(() => {
            this.$bvModal.show('modalDetail')
          }, 500)
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    async onUpdate(data) {
      const { id, status } = data
      this.loading = true
      const url = `/v1/divisions/${id}/update-activation`
      const payload = {
        status,
      }
      await komtimAxiosIns
        .put(url, payload)
        .then(() => {
          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)

          this.getListData()
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id, edit) {
      if (!edit) this.idItem = id
      else {
        this.idItem = id
        this.getDetailDivision(id)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './style/Index.scss';
</style>
